const Texts = {
  PT: {
    title: 'Categoria Game Jam',
    text: `
      <p class="strong">Bem-vindos a GAMEJAM+2020!</p>
      <p>GAMEJAM+ é um movimento colaborativo, sem fins lucrativos, de fomento ao empreendedorismo na indústria de economia criativa. Somos uma maratona e aceleração de desenvolvimento de jogos e gamificação que tem como objetivo transformar jogos em negócio.</p>
      <p>A edição 2020 da copa do mundo de desenvolvimento de games, contará com 2 categorias.
      A tradicional categoria <span class="strong">“game jam”</span>, a queridinha dos competidores, e a nova categoria “publisher”.</p>
      <p>A categoria <span class="strong">“game jam”</span> será dividida em 4 etapas:</p>
      <ol>
        <li>
          <p><span class="strong">Primeira etapa -</span> game jams simultâneas em mais de 15 países no mundo.</p>
          <p><span class="strong">Data:</span> 9 a 11 de Outubro.</p>
          <p>Como as game jams que você já conhece, os participantes terão 54h para elaborar e desenvolver um jogo DO ZERO! Após o desenvolvimento, você irá apresentá-lo para uma banca de jurados – da mesma forma que se faria no marketing e venda de um projeto para grandes investidores.</p>
          <p class="strong">Instruções:</p>
          <ul>
            <li>A build dos jogos deve ser submetida no itch.io (link para a nossa página) até 23:59 do dia 11/10 (fuso horário local);</li>
            <li>O vídeo de pitch com (até 5 minutos) deve ser enviado até as 23:59 do dia 13/10 (fuso horário local);</li>
            <li>Até o dia 1° de novembro anunciaremos os dois ganhadores de cada cidade.</li>
          </ul>
        </li>
        <li>
          <p><span class="strong">Segunda etapa -</span> mentorias online com profissionais de várias áreas, como: programação, negócios, marketing, arte e etc. Todos profissionais ativos no mercado nacional e internacional, com alto nível de expertise.</p>
          <p><span class="strong">Data:</span> Novembro, Dezembro e Janeiro.</p>
        </li>
        <li>
          <p><span class="strong">Terceira etapa -</span>  Esta etapa é apenas para as equipes campeãs da primeira etapa que não conseguirem financiamento para custear a viagem para a grande final. Esta etapa não é eliminatória. As semifinais serão: 
          </p>
          <ul>
            <li>América Latina</li>
            <li>África e Oriente Médio</li>
            <li>América do Norte</li>
            <li>Europa</li>
            <li>Ásia e Oceania</li>
          </ul>
          <p><span class="strong">Data:</span> Fevereiro.</p>
        </li>
        <li>
          <p><span class="strong">Quarta etapa -</span> A Grande Final</p>
          <p>É o momento em que os finalistas e organizadores se encontram presencialmente na cidade de São Paulo (Brasil), a fim de definir e celebrar os grandes vencedores. Esta etapa irá proporcionar momentos únicos de networking, trocas de experiência e claro, muitos jogos.</p>
          <p><span class="strong">Data:</span> 5 a 9 de Maio.</p>
        </li>
      </ol>
      <small>Observação: É de responsabilidade do participante (finalista) os custos com passagem e hospedagem para final. Consulte o seu organizador local se há algum tipo de subsídio para a sua cidade.</small>
    `,
    instrucoes: `Instruções para inscrição:`,
    instrucoesText: `
      <ol>
        <li>Leia atentamente todas as informações;</li>
        <li>Encontre a sua cidade e registre-se na plataforma eventbrite <a href="https://www.eventbrite.com.br/o/game-jam-21753100209">clicando aqui</a> ou no botão "Inscreva-se", e aguarde pelo e-mail de confirmação. Neste e-mail haverá um link para o nosso formulário online para que você preencha e se cadastre;</li>
        <li>Caso não tenha equipe formada ou se estiver procurando por mais membros para o seu time, entre em contato com o seu organizador local.</li>
      </ol>
      <small>Atenção: As inscrições acontecem até o dia 8 de outubro.</small>
    `,
    participar: `O que você ganha por participar da categoria “game jam”?`,
    participarText: `
      <p>Além de participar dessa incrível competição global, e entrar na disputa de todos os prêmios, você:</p>
      <ul>
        <li>Ajuda a fomentar o ecossistema de desenvolvimento de games no Brasil e no mundo;</li>
        <li>Ganha acesso em 50% das nossas palestras vip na segunda etapa;</li>
        <li>Recebe certificado de participação da GAMEJAM+2020 com 60 horas complementares (primeira etapa) e 120 horas (até a quarta etapa);</li>
        <li>Adquire 20 dias de acesso gratuito na Alura cursos online;</li>
        <li>Acesso de 20 dias de curso gratuito de “Comportamento do Jogador e Psicologia Aplicada a Games” pela Escola Brasileira de Games.</li>
      </ul>
    `,
    premiacoes: `Premiações da GAMEJAM+2020 para a categoria “game jam”:`,
    premiacoesText: `
      <ul>
        <li>
          <p><span class="strong">Primeira etapa:</span> todos os campeões regionais (duas equipes por cidade) garantem a sua vaga na final, e um time por cidade terá direito a um ingresso para rodada de negócios no Big Festival;</p>
          <ul>
            <li>
              <p><span class="strong">Categoria Esportes / Hacking.Rio / Flamengo*:</span> R$5.000;<br/>
              <small>*Mais informações no keynotes de abertura</small></p>
            </li>
          </ul>
          <p><small>Observação: todas as despesas com passagem e hospedagem são de responsabilidade do participante. Confira com o seu organizador local se existe algum tipo de patrocínio.</small></p>
        </li>
        <li>
          <p><span class="strong">Segunda etapa:</span> workshops exclusivos com google, unity, wildlife e outros;</p>
        </li>
        <li>
          <p><span class="strong">Terceira etapa:</span> todos os campeões continentais (Ásia/Oceania, África, Europa, América e Brasil) ganham IGDA membership;</p>
        </li>
        <li>
          <p><span class="strong">Quarta etapa:</span> Grande final no Brasil;</p>
          <ul>
            <li>
              <span class="strong">Voto dos jurados de melhor gameplay para o 1ºlugar:</span>  
              troféu + 1 ano de 1 (uma) licença Unity Pro +; 
            </li>
            <li>
              <span class="strong">Voto dos jurados de melhor pitch para o 1ºlugar:</span>  
              troféu + 1 ano de 1 (uma) licença Unity Pro +; 
            </li>
            <li>
              <span class="strong">Voto dos jurados para o 2º e 3º lugares:</span>  
              troféu; 
            </li>
            <li>
              <span class="strong">Prêmio especial </span>  
              para melhor game mobile, melhor game web, melhor game designer, melhor trilha sonora, melhor game de diversidade, melhor game de impacto social, melhor pitch e melhor narrativa: troféu;
            </li>
            <li>
              <span class="strong">Prêmio especial para categoria diversidade:</span>  
              aproximadamente R$2800; 
            </li>
            <li>
              <span class="strong">Prêmio melhor jogo de impacto social:</span>  
              aproximadamente R$1400; 
            </li>
            <li>
              <span class="strong">Prêmio melhor jogo web:</span>  
              R$400 em jogos na Nuuvem.
            </li>
          </ul>
        </li>
      </ul>
    `,
    pagar: `Por que pagar pela GAMEJAM+?`,
    pagarText: `
      <p>Em 2020, o orçamento total do projeto é de US$5.000,00. A força do nosso movimento está no poder da colaboração (são quase 1000 voluntários no mundo). Poderíamos esperar algum governo ou empresa privada financiar as passagens e hospedagens para final, mas entendemos que o cidadão é o principal agente de transformação do meio em que vive e assim, entendemos que os participantes que têm poder aquisitivo* poderiam também contribuir com a causa.</p>
      <p>É fundamental destacar que 100% da receita é revertida para ajudar os finalistas a irem à final. Isto é, vocês pagam e se beneficiam diretamente, caso sejam finalistas.</p>
      <p><small>*O objetivo da cobrança não é excluir ninguém. Se R$50,00 for um valor totalmente inviável para a sua contribuição, entre em contato com o organizador local da sua cidade e verifique a disponibilidade de gratuidade. </small></p>
    `,
    button: 'Inscreva-se',
    buttonLink: 'https://www.eventbrite.com.br/o/game-jam-21753100209',
    video1: 'Video 1',
    video2: 'Video 2',
  },
  EN: {
    title: 'Game Jam Category',
    text: `
      <p class="strong">Welcome to GAMEJAM+2020!</p>
      <p>GAMEJAM+ is a collaborative, non-profit movement promoting entrepreneurship in the creative economy industry. We are a marathon and acceleration of game development and gamification that aims to transform games into business.</p>
      <p>The 2020 edition of the game development world cup will feature 2 categories.
      The traditional category <span class="strong">“game jam”</span>, the darling of the competitors, and the new category  “publisher”.</p>
      <p>The <span class="strong">“game jam”</span> category will be divided into 4 stages:</p>
      <ol>
        <li>
          <p><span class="strong">First stage -</span> simultaneous game jams in more than 15 countries worldwide.</p>
          <p><span class="strong">Date:</span> October 9th to 11th.</p>
          <p>Like the game jams you already know, participants will have 54 hours to design and develop a game FROM SCRATCH! After development, you will introduce it to a panel of judges - just as you would in marketing and selling a project to major investors.</p>
          <p class="strong">Instructions:</p>
          <ul>
            <li>The game build must be submitted on itch.io (link to our page) until 11:59 pm on 10/11 (local time zone);</li>
            <li>Pitch video with (up to 5 minutes) must be sent by 11:59 pm on 10/13 (local time zone);</li>
            <li>Until November 1st, we will announce the two winners from each city.</li>
          </ul>
        </li>
        <li>
          <p><span class="strong">Second stage -</span> online mentoring with professionals from various areas, such as: programming, business, marketing, art and etc. All professionals active in the national and international market, with a high level of expertise.</p>
          <p><span class="strong">Date:</span> November, December and January.</p>
        </li>
        <li>
          <p><span class="strong">Third stage -</span> This stage is only for the champion teams of the first stage that are unable to obtain financing to pay for the trip to the grand final. This step is not eliminatory. The semifinals will be: 
          </p>
          <ul>
            <li>Latin America</li>
            <li>Africa and Middle East</li>
            <li>North America</li>
            <li>Europe</li>
            <li>Asia and Oceania</li>
          </ul>
          <p><span class="strong">Date:</span> February.</p>
        </li>
        <li>
          <p><span class="strong">Fourth stage -</span> The Grand Final</p>
          <p>It is the moment when the finalists and organizers meet in person in the city of São Paulo (Brazil), in order to define and celebrate the big winners. This stage will provide unique moments of networking, exchanges of experience and of course, many games.</p>
          <p><span class="strong">Date:</span> May 5 to 9.</p>
        </li>
      </ol>
      <small>Note: It is the participant's (finalist's) responsibility to pay for the ticket and accommodation for the final. Consult your local organizer if there is any kind of subsidy for your city.</small>
    `,
    instrucoes: `Registration instructions:`,
    instrucoesText: `
      <ol>
        <li>Read all information carefully;</li>
        <li>Find your city and register on the platform eventbrite <a href="https://www.eventbrite.com.br/o/game-jam-21753100209">clicking here</a> or on the button "Make Your Registration!", and wait for the confirmation email. In this email there will be a link to our online form for you to complete and register;</li>
        <li>If you do not have a team or if you are looking for more members for your team, contact your local organizer.</li>
      </ol>
      <small>Attention: Registration takes place until October 8th.</small>
    `,
    participar: `What do you get for participating in the “game jam” category?`,
    participarText: `
      <p>In addition to participating in this incredible global competition, and entering into the contest for all prizes, you will:</p>
      <ul>
        <li>Help to foster the game development ecosystem in Brazil and worldwide;</li>
        <li>Gain access to 50% of our VIP lectures in the second stage;</li>
        <li>Receives GAMEJAM+2020 participation certificate with 60 additional hours (first stage) and 120 hours (until the fourth stage);</li>
        <li>Purchase 20 days of free access to Alura online courses;</li>
        <li>Access to a 20-day free course in “Player Behavior and Psychology Applied to Games” by Escola Brasileira de Games.</li>
      </ul>
    `,
    premiacoes: `GAMEJAM+ 2020 awards for the “game jam” category:`,
    premiacoesText: `
    <ul>
      <li>
        <p><span class="strong">First stage:</span> all regional champions (two teams per city) guarantee their place in the final, and one team per city will be entitled to a ticket for a business round at the Big Festival;</p>
        <ul>
          <li>
            <p><span class="strong">Category Sports / Hacking.Rio / Flamengo*:</span> approximately US$890;<br/>
            <small>*More information on the opening keynotes</small></p>
          </li>
        </ul>
        <p><small>Note: all expenses with tickets and accommodation are the participant's responsibility. Check with your local organizer if there is any sponsorship.</small></p>
      </li>
      <li>
        <p><span class="strong">Second stage:</span> exclusive workshops with google, unity, wildlife and others;</p>
      </li>
      <li>
        <p><span class="strong">Third stage:</span> all continental champions (Asia/Oceania, Africa, Europe, America and Brazil) gain IGDA membership;</p>
      </li>
      <li>
        <p><span class="strong">Fourth stage:</span> Grand final in Brazil;</p>
        <ul>
          <li>
            <span class="strong">Vote by the judges for the best gameplay for the 1st place:</span>  
            trophy + 1 year of 1 (one) Unity Pro + license;
          </li>
          <li>
            <span class="strong">Vote of the judges with the best pitch for the 1st place:</span>  
            trophy + 1 year of 1 (one) Unity Pro + license;
          </li>
          <li>
            <span class="strong">Vote of the jurors for the 2nd and 3rd places:</span>  
            trophy; 
          </li>
          <li>
            <span class="strong">Special award </span>  
            for best mobile game, best web game, best game designer, best soundtrack, best diversity game, best social impact game, best pitch and best narrative: trophy; 
          </li>
          <li>
            <span class="strong">Special award for diversity category:</span>  
            US$500; 
          </li>
          <li>
            <span class="strong">Best social impact game award:</span>  
            US$250; 
          </li>
          <li>
            <span class="strong">Best web game award:</span>  
            approximately US$70 in games on the Nuuvem.
          </li>
        </ul>
      </li>
    </ul>
    `,
    pagar: `Why pay for GAMEJAM+?`,
    pagarText: `
      <p>In 2020, the total budget for the project is US$5,000.00. The strength of our movement is in the power of collaboration (there are almost 1000 volunteers in the world). We could expect some government or private company to finance tickets and accommodation for the end, but we understand that the citizen is the main agent of transformation of the environment in which he lives and, therefore, we understand that participants who have purchasing power* could also contribute to the cause.</p>
      <p>It is essential to highlight that 100% of the revenue is reverted to help the finalists to go to the final. That is, you pay and benefit directly if you are a finalist.</p>
      <p><small>*The purpose of charging is not to exclude anyone. If R$50.00 is totally unviable for your contribution, contact the local organizer of your city and check the availability of free of charge.</small></p>
    `,
    button: 'Make Your Registration!',
    buttonLink: 'https://www.eventbrite.com.br/o/game-jam-21753100209',
    video1: 'Video 1',
    video2: 'Video 2',
  }
}

export default Texts