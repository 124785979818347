import React from 'react';
import styled from 'styled-components'

//Helpers
import Title from '../helpers/Title'

//Components
import Layout from "../components/Layout"
import Language from "../components/Language"
import Seo from "../components/seo"

import bolinha from '../assets/images/bolinha1.png';
import oqGanha from '../assets/images/oqGanha.png';
import premiacao from '../assets/images/premiacao.png';
import pqPagar from '../assets/images/pqPagar.png';
import texts from "../components/GameJam/content"

const GameJam = () => (
  <Layout>
    <Seo title="GameJam" />
      <Container>
        <Title>{Language(texts).title}</Title>
        <Text
          dangerouslySetInnerHTML={{ __html: `${Language(texts).text}` }}
        >
        </Text>
        <Button
          href={Language(texts).buttonLink}
          target='_BLANK'
          rel="noopener noreferrer"
        >
          {Language(texts).button}
        </Button>
        <Content>
          <Image src={bolinha} alt='' />
          <Box>
            <Subtitle>{Language(texts).instrucoes}</Subtitle>
            <Text dangerouslySetInnerHTML={{ __html: `${Language(texts).instrucoesText}` }}></Text>
          </Box>
        </Content>
        <Content>
          <Image src={oqGanha} alt='' />
          <Box>
            <Subtitle>{Language(texts).participar}</Subtitle>
            <Text dangerouslySetInnerHTML={{ __html: `${Language(texts).participarText}` }}></Text>
          </Box>
        </Content>
        <Content>
          <Image src={premiacao} alt='' />
          <Box>
            <Subtitle>{Language(texts).premiacoes}</Subtitle>
            <Text dangerouslySetInnerHTML={{ __html: `${Language(texts).premiacoesText}` }}></Text>
          </Box>
        </Content>
        <Content>
          <Image src={pqPagar} alt='' />
          <Box>
            <Subtitle>{Language(texts).pagar}</Subtitle>
            <Text dangerouslySetInnerHTML={{ __html: `${Language(texts).pagarText}` }}></Text>
          </Box>
        </Content>
        <Button
          href={Language(texts).buttonLink}
          target='_BLANK'
          rel="noopener noreferrer"
        >
          {Language(texts).button}
        </Button>
      </Container>
  </Layout>
)

export default GameJam

const Container = styled.section`
  width: 100%;
  max-width: 1240px;
  margin: 2rem auto;
  padding: 0 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 768px) {
  }
`

const Text = styled.div`
  margin-bottom: 2rem;
  font-family: var(--regular);
  font-size: 1.25rem;
  color: var(--blue);
  text-align: justify;
  line-height: 1.75rem;

  .strong {
    font-family: var(--bold);
    font-weight: bolder;
  }

  p {
    margin-bottom: 1rem;
  }

  ul {
    margin-bottom: 1rem;
    list-style: disc;
  }

  ol {
    margin-bottom: 1rem;
    list-style: decimal;
  }

  li {
    margin-bottom: 1rem;
    margin-left: 2rem;
  }

  small {
    font-size: 1rem;
  }

  @media screen and (max-width: 768px) {
    text-align: left;
  }
`

const Button = styled.a`
  display: block;
  margin-bottom: 4rem;
  padding: 1rem 2rem;
  border: none;
  border-radius: .5rem;
  background: #67a99a;
  font-family: var(--bold);
  font-size: 1rem;
  color: #FFF;

  @media screen and (max-width: 768px) {
  }
`
const Content = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`
const Image = styled.img`
  width: 30%;

  @media screen and (max-width: 768px) {
    width: 80%;
    margin-bottom: 2rem;
  }
`
const Box = styled.div`
  width: 65%;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

const Subtitle = styled.h3`
  font-family: var(--primary);
  font-size: 2rem;
  margin-bottom: 1rem;

  @media screen and (max-width: 768px) {
    font-size: 1.5rem;
  }
`
// const Container = styled.section`

//   @media screen and (max-width: 768px) {
//   }
// `
